//styles
import "../../styles.css";

import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import { useSelector } from "react-redux";
import { selectUser } from "state/slices/authSlice";
//components
import Header from "../store/Header";
import Payments from "./Payments";
import DeliveryMethod from "./DeliveryMethod";

export const Item = ({ item, i, layout }) => {
  const id = item?.id;
  const quantity = item?.quantity;
  const itemDetails = item?.payload?.item;
  // const itemExtras = item?.payload?.itemExtras;
  const itemExtras = item?.payload?.extras;

  let itemExtrasTotal = 0;

  // if (itemExtras) {
  //   Object.values(itemExtras)?.forEach((value) => {
  //     const [_type, cost] = value?.split(",");
  //     itemExtrasTotal += Number(cost);
  //   });
  // }
  if (itemExtras) {
    Object.entries(itemExtras).forEach(([groupName, selectedValues]) => {
      const extraGroup = itemDetails.extras?.find(
        (e) => e.option === groupName
      );
      if (extraGroup) {
        const values = Array.isArray(selectedValues)
          ? selectedValues
          : [selectedValues];
        values.forEach((value) => {
          const option = extraGroup.options.find((opt) => opt.value === value);
          if (option) {
            itemExtrasTotal += Number(option.cost);
          }
        });
      }
    });
  }
  return (
    <ItemContainer
      key={i + id + itemDetails?.name}
      className={"cart-item " + layout?.cart?.item}
    >
      <div
        style={{
          marginBottom: "12px",
          fontWeight: "bold",
        }}
        className={"cart-itemName " + layout?.cart?.itemName}
      >
        {itemDetails?.name}
      </div>
      <div
        style={{ display: "flex", justifyContent: "flex-end" }}
        className={"cart-itemQuantity " + layout?.cart?.itemQuantity}
      >
        x{quantity}
      </div>
      {itemExtras && (
        <div style={{ fontSize: "14px" }}>
          {Object.entries(itemExtras).map(([groupName, selectedValues], i) => {
            const extraGroup = itemDetails.extras?.find(
              (e) => e.option === groupName
            );
            if (!extraGroup) return null;

            return (
              <div key={i} style={{ margin: "6px 0 0" }}>
                <div>
                  <strong>{groupName}:</strong>
                </div>
                {Array.isArray(selectedValues) ? (
                  selectedValues.map((value, idx) => {
                    const option = extraGroup.options.find(
                      (opt) => opt.value === value
                    );
                    return (
                      <div key={idx}>
                        - {option?.text}
                        {option?.cost > 0 && ` (+€${option.cost.toFixed(2)})`}
                      </div>
                    );
                  })
                ) : (
                  <div>
                    -{" "}
                    {
                      extraGroup.options.find(
                        (opt) => opt.value === selectedValues
                      )?.text
                    }
                    {extraGroup.options.find(
                      (opt) => opt.value === selectedValues
                    )?.cost > 0 &&
                      ` (+€${extraGroup.options
                        .find((opt) => opt.value === selectedValues)
                        ?.cost.toFixed(2)})`}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      <div
        style={{
          gridColumn: "2 / 3",
          display: "grid",
          justifyContent: "flex-end",
          alignItems: "end",
          fontWeight: "bold",
        }}
        className={"cart-itemCost " + layout?.cart?.itemCost}
      >
        €
        {((Number(itemDetails?.price) + itemExtrasTotal) * quantity).toFixed(2)}
      </div>
    </ItemContainer>
  );
};

const Checkout = () => {
  const [deliveryMethod, setDeliveryMethod] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [buyersName, setBuyersName] = useState(null);
  const [discountAmount, setDiscountAmount] = useState("0.00");
  const [cartTotal, setCartTotal] = useState("0.00");
  const [useXAmountOfRewards, setUseXAmountOfRewards] = useState(0);
  const [rewardSavings, setRewardSavings] = useState("0.00");

  const { items: cart } = useSelector((state) => state.cart);
  const { store } = useSelector((state) => state.store);
  const user = useSelector(selectUser);
  const { layout, deliveryMethods, paymentMethods } = store;
  const navigate = useNavigate();
  let location = useLocation();
  const { items } = location.state || [];
  console.log("ANDY user data:", { user });
  const livePromos = [
    //will get this array from database
    { code: "123", discount: 2, type: "-" },
    { code: "000", discount: 1, type: "-" },
    { code: "free", discount: 100, type: "%" },
    { code: "10off", discount: 10, type: "%" },
    { code: "20off", discount: 20, type: "%" },
    { code: "50off", discount: 50, type: "%" },
    { code: "80off", discount: 80, type: "%" },
  ];

  console.log("ANDY data", {
    buyersName,
    promoCode,
    deliveryMethod,
    selectedPaymentMethod,
    items,
    cart,
    store,
  });

  useEffect(() => {
    if (user?.firstName || user?.storeName) {
      setBuyersName(user?.firstName || user?.storeName);
    }
  }, []);

  useEffect(() => {
    // if (deliveryMethods?.length > 0) {
    setDeliveryMethod(deliveryMethods?.[0].value);
    // }
    // if (paymentMethods?.length > 0) {
    setSelectedPaymentMethod(paymentMethods?.[0].value);
    // }
  }, [deliveryMethods, paymentMethods]);

  useEffect(() => {
    let originalTotal = 0;
    const eligibleUnits = [];

    cart?.forEach((item) => {
      const itemDetails = item?.payload?.item;
      const itemExtras = item?.payload?.extras;
      let itemExtrasTotal = 0;

      if (itemExtras) {
        Object.entries(itemExtras).forEach(([groupName, selectedValues]) => {
          const extraGroup = itemDetails.extras?.find(
            (e) => e.option === groupName
          );
          if (extraGroup) {
            const values = Array.isArray(selectedValues)
              ? selectedValues
              : [selectedValues];
            values.forEach((value) => {
              const option = extraGroup.options.find(
                (opt) => opt.value === value
              );
              if (option) {
                itemExtrasTotal += Number(option.cost || 0);
              }
            });
          }
        });
      }

      const pricePerUnit = Number(itemDetails?.price || 0) + itemExtrasTotal;
      const itemTotal = pricePerUnit * item.quantity;
      originalTotal += itemTotal;

      if (itemDetails?.addLoyaltyStamp) {
        for (let i = 0; i < item.quantity; i++) {
          eligibleUnits.push(pricePerUnit);
        }
      }
    });

    // Calculate rewards discount
    let rewardsDiscount = 0;
    if (useXAmountOfRewards > 0 && eligibleUnits.length > 0) {
      const sortedEligibleUnits = [...eligibleUnits].sort((a, b) => a - b);
      const discountedUnits = sortedEligibleUnits.slice(0, useXAmountOfRewards);
      rewardsDiscount = discountedUnits.reduce((acc, price) => acc + price, 0);
    }

    // Update both cart total and reward savings
    const total = originalTotal - rewardsDiscount;
    setCartTotal(total.toFixed(2));
    setRewardSavings(rewardsDiscount.toFixed(2)); // Track rewards savings
  }, [cart, useXAmountOfRewards]);

  // useEffect(() => {
  //   let originalTotal = 0;
  //   const eligibleUnits = [];

  //   cart?.forEach((item) => {
  //     const itemDetails = item?.payload?.item;
  //     const itemExtras = item?.payload?.extras;
  //     let itemExtrasTotal = 0;

  //     // Calculate extras total
  //     if (itemExtras) {
  //       Object.entries(itemExtras).forEach(([groupName, selectedValues]) => {
  //         const extraGroup = itemDetails.extras?.find(
  //           (e) => e.option === groupName
  //         );
  //         if (extraGroup) {
  //           const values = Array.isArray(selectedValues)
  //             ? selectedValues
  //             : [selectedValues];
  //           values.forEach((value) => {
  //             const option = extraGroup.options.find(
  //               (opt) => opt.value === value
  //             );
  //             if (option) {
  //               itemExtrasTotal += Number(option.cost || 0);
  //             }
  //           });
  //         }
  //       });
  //     }

  //     const pricePerUnit = Number(itemDetails?.price || 0) + itemExtrasTotal;
  //     const itemTotal = pricePerUnit * item.quantity;
  //     originalTotal += itemTotal;

  //     // Check if item is eligible for rewards
  //     if (itemDetails?.addLoyaltyStamp) {
  //       // Add each unit as a separate entry
  //       for (let i = 0; i < item.quantity; i++) {
  //         eligibleUnits.push(pricePerUnit);
  //       }
  //     }
  //   });

  //   // Calculate rewards discount
  //   let rewardsDiscount = 0;
  //   if (useXAmountOfRewards > 0 && eligibleUnits.length > 0) {
  //     // Sort eligible units by price ascending
  //     const sortedEligibleUnits = [...eligibleUnits].sort((a, b) => a - b);
  //     // Take the first 'useXAmountOfRewards' units
  //     const discountedUnits = sortedEligibleUnits.slice(0, useXAmountOfRewards);
  //     rewardsDiscount = discountedUnits.reduce((acc, price) => acc + price, 0);
  //   }

  //   const total = originalTotal - rewardsDiscount;
  //   setCartTotal(total.toFixed(2));
  // }, [cart, useXAmountOfRewards]);

  useEffect(() => {
    if (!promoCode) {
      setDiscountAmount("0.00");
      return;
    }

    const validPromo = livePromos.find(
      (promo) => promo.code.toLowerCase() === promoCode.toLowerCase()
    );

    if (validPromo) {
      let discount = 0;
      if (validPromo.type === "-") {
        discount = validPromo.discount;
      } else if (validPromo.type === "%") {
        discount = (Number(cartTotal) * validPromo.discount) / 100;
      }
      // Ensure discount does not exceed cartTotal
      discount = Math.min(discount, Number(cartTotal));
      setDiscountAmount(discount.toFixed(2));
    } else {
      setDiscountAmount("0.00");
    }
  }, [promoCode, cartTotal, livePromos]);

  const totalItems = cart.reduce((acc, item) => acc + item.quantity, 0);
  const totalItemsEligibleForRewards = cart.reduce(
    (acc, item) =>
      acc + (item?.payload?.item?.addLoyaltyStamp ? item.quantity : 0),
    0
  );

  const LoyaltyRewards = user?.loyaltyStamps.find(
    (stamp) => stamp?.company === store?.company
  );

  const finalTotal = (Number(cartTotal) - Number(discountAmount)).toFixed(2);

  return (
    <CheckoutWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
          padding: "12px 0",
        }}
      >
        <span
          onClick={() => navigate(-1)}
          style={{ fontSize: "24px", cursor: "pointer" }}
        >
          &larr;
        </span>
        <h3 style={{ fontSize: "18px" }}>Checkout</h3>
      </div>
      <div
        className="checkout-cart-items"
        style={{
          margin: "6px 0",
          borderRadius: "4px",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 1px 8px",
        }}
      >
        {items?.map((item, i) => (
          <Item key={i} item={item} i={i} layout={layout} />
        ))}
      </div>
      <CheckoutFormWrapper className="checkout-form">
        <OrderInputInfo>
          <div>
            <div>Name: </div>
            <input
              type="text"
              placeholder={buyersName || "Name"}
              // value={
              //   user ? user?.firstName || user?.storeName : buyersName || ""
              // }
              onChange={(e) => setBuyersName(e.target.value)}
              style={{ ...(!buyersName && { outline: "2px solid red" }) }}
            />
          </div>
          <DeliveryMethod
            layout={layout}
            deliveryMethod={deliveryMethod}
            setDeliveryMethod={setDeliveryMethod}
            deliveryMethods={deliveryMethods}
          />
          <div>
            <div>Promo Code: </div>
            <input
              type="text"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
            />
          </div>
          {LoyaltyRewards?.rewardsAvailable > 0 && (
            <div>
              <div>Rewards Available:</div>
              <select
                defaultValue={useXAmountOfRewards}
                value={useXAmountOfRewards}
                onChange={(e) => setUseXAmountOfRewards(Number(e.target.value))}
              >
                {Array.from(
                  { length: LoyaltyRewards?.rewardsAvailable + 1 },
                  (_, i) => i
                ).map((reward, i) => {
                  if (reward === 0) {
                    return (
                      <option key={`item-${i}-${reward}`} value={reward}>
                        None
                      </option>
                    );
                  } else if (reward <= totalItemsEligibleForRewards) {
                    return (
                      <option key={`item-${i}-${reward}`} value={reward}>
                        {reward}
                      </option>
                    );
                  }
                })}
              </select>
            </div>
          )}
          <div>
            <div>Price Breakdown: </div>
            <div style={{ fontWeight: "bold" }}>
              <div style={{ marginBottom: "6px" }}>
                Subtotal: €
                {(Number(cartTotal) + Number(rewardSavings)).toFixed(2)}
              </div>
              {discountAmount !== "0.00" && (
                <div style={{ marginBottom: "6px" }}>
                  Discount: -€{discountAmount}
                </div>
              )}
              {rewardSavings > 0 && (
                <div style={{ marginBottom: "6px" }}>
                  Rewards Savings: -€{rewardSavings}
                </div>
              )}
              <div>Total: €{finalTotal}</div>
            </div>
            {/* Implement logic to apply promotions and rewards */}
          </div>
        </OrderInputInfo>
        <Payments
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          paymentMethods={paymentMethods}
          orderAmount={finalTotal}
          deliveryMethod={deliveryMethod}
          buyersName={buyersName}
          promoCode={promoCode}
          discountAmount={discountAmount}
          rewardSavings={rewardSavings}
          useXAmountOfRewards={useXAmountOfRewards}
        />
      </CheckoutFormWrapper>
    </CheckoutWrapper>
  );
};

export default Checkout;

const CheckoutWrapper = styled.div`
  padding: 0 12px;
`;

const CheckoutFormWrapper = styled.div``;

const OrderInputInfo = styled.div`
  margin: 6px 0px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 8px;
  padding: 6px 12px;

  & > div {
    // display: flex;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 12px;
    margin: 12px 0;

    & > div {
      font-weight: bold;
    }
  }
`;

const ItemContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;

  padding: 12px;
  border-bottom: 1px solid lightgrey;
`;
