import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem: (state, action) => {
      // Find index using the new extras structure
      const index = state.items.findIndex((item) => {
        return (
          item?.id === action.payload.id &&
          JSON.stringify(item?.payload?.extras) ===
            JSON.stringify(action?.payload?.payload?.extras)
        );
      });

      if (index !== -1) {
        state.items[index] = {
          ...state.items[index],
          ...action.payload,
        };
      } else {
        state.items.push(action.payload);
      }
    },
    updateItemQuantity: (state, action) => {
      const index = state.items.findIndex(
        (item) =>
          item.id === action.payload.id &&
          JSON.stringify(item.payload.extras) ===
            JSON.stringify(action.payload.extras)
      );
      if (index >= 0) {
        state.items[index].quantity = action.payload.quantity;
      }
    },
    removeItem: (state, action) => {
      state.items = state.items.filter(
        (item) =>
          !(
            item.id === action.payload.id &&
            JSON.stringify(item.payload.extras) ===
              JSON.stringify(action.payload.extras)
          )
      );
    },
    clearCart: (state) => {
      state.items = [];
    },
  },
});

// Action creators
export const { addItem, updateItemQuantity, removeItem, clearCart } =
  cartSlice.actions;

export default cartSlice.reducer;
