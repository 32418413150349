import React from "react";
import styled, { keyframes } from "styled-components";

// Keyframes for the fade-in animation.
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// A container for the entire loyalty card.
const CardContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 16px;
  background-color: #606060;
`;

// Card title styling.
const CardTitle = styled.h3`
  padding: 16px;
  font-size: 24px;
  text-align: center;
  background-color: lightslategray;
  color: seashell;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

// Grid container that divides the card evenly.
// The number of columns and rows are passed in as props.
const StampGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-template-rows: repeat(${(props) => props.rows}, 1fr);
  gap: 4px;
  padding: 4px;
`;

// Each cell is a square.
const StampCell = styled.div`
  border: 1px solid #ccc;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  background-color: seashell;
  border-radius: 25%;
`;

// The stamp image is centered in the cell, rotated by a random angle,
// and will fade in with a delay.
const StampImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 90%;
  object-fit: contain;
  border-radius: 100%;
  transform: translate(-50%, -50%) rotate(${(props) => props.rotation}deg);
  opacity: 0;
  animation: ${fadeIn} 0.5s ease forwards;
  animation-delay: ${(props) => props.delay}s;
`;

// Utility function to determine grid dimensions.
// We choose the number of columns as the ceiling of the square root
// and then compute rows accordingly.
const getGridDimensions = (stampsNeeded) => {
  if (stampsNeeded <= 0) return { columns: 0, rows: 0 };
  const columns = Math.ceil(Math.sqrt(stampsNeeded));
  const rows = Math.ceil(stampsNeeded / columns);
  return { columns, rows };
};

// A single loyalty card component.
const LoyaltyCard = ({ loyaltyCard }) => {
  const {
    company,
    stampsCollected,
    stampsRequiredForReward,
    stampImage = "https://picsum.photos/50",
  } = loyaltyCard;

  // Handle cases where no stamps are required.
  if (stampsRequiredForReward <= 0) {
    return (
      <CardContainer>
        <CardTitle>{company}</CardTitle>
        <p>No stamps required</p>
      </CardContainer>
    );
  }

  // Get grid layout dimensions.
  const { columns, rows } = getGridDimensions(stampsRequiredForReward);

  // Determine how many cells should display the stamp image.
  // If stampsCollected is less than the needed stamps, use that number;
  // otherwise, use stampsCollected % stampsRequiredForReward.
  const stampsToShow =
    stampsCollected < stampsRequiredForReward
      ? stampsCollected
      : stampsCollected % stampsRequiredForReward;

  // Create an array representing each cell (true means stamped).
  const cells = Array.from(
    { length: stampsRequiredForReward },
    (_, index) => index < stampsToShow
  );

  return (
    <CardContainer>
      <CardTitle>{company}</CardTitle>
      <StampGrid columns={columns} rows={rows}>
        {cells.map((isStamped, idx) => (
          <StampCell key={idx}>
            {isStamped && (
              <StampImage
                src={stampImage}
                rotation={Math.floor(Math.random() * 360)}
                alt="stamp"
                // Each stamped image fades in 0.3s after the previous one.
                delay={idx * 0.5}
              />
            )}
          </StampCell>
        ))}
      </StampGrid>
    </CardContainer>
  );
};

export default LoyaltyCard;
