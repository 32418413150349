import React from "react";
import styled from "styled-components";

const FooterContainer = styled.p`
  position: relative;
  width: 100%;
  height: 34px;
  padding-top: 16px;
  text-align: center;
`;

const Footer = () => {
  return (
    <FooterContainer>
      Powered By <span style={{ color: "#402D77", fontWeight: "bold" }}>Orderly</span>
    </FooterContainer>
  );
};

export default Footer;
