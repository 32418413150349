import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { logout } from "../../state/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setLocalStorageWithExpiry,
  getLocalStorage,
  deleteLocalStorage,
} from "helpers/localStorage";
import dayjs from "dayjs";

import Loading from "components/Loading";
import LoyaltyCard from "./LoyaltyCard";

const Button = ({ onClick, children }) => {
  return (
    <button
      onClick={onClick}
      style={{ padding: "12px 20px", cursor: "pointer", margin: "5px" }}
    >
      {children}
    </button>
  );
};

export const Modal = ({ title, isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          margin: "20px",
          borderRadius: "5px",
          width: "500px",
          minHeight: "200px",
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        {title && (
          <h2
            style={{
              marginBottom: "16px",
              fontSize: "24px",
              textAlign: "center",
            }}
          >
            {title}
          </h2>
        )}
        {children}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={onClose}>Close</Button>
        </div>
      </div>
    </div>
  );
};

const UserAccount = () => {
  const [latestUserData, setLatestUserData] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedLoyaltyCard, setSelectedLoyaltyCard] = useState(null);
  const userData = getLocalStorage("userSession");
  console.log("ANDY userAccount", { latestUserData, userData });
  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (userData?._id) {
          const response = await fetch(`/api/users/${userData?._id}`);

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const user = await response.json();
          setLatestUserData(user);

          if (userData && JSON.stringify(userData) !== JSON.stringify(user)) {
            setLocalStorageWithExpiry("userSession", user);
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchUser();
  }, [userData?._id]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    // try {  - DOES NOT WORK
    //   const response = await fetch("/api/users/logout", { method: "POST" });
    //   const data = await response.json();

    //   if (response.ok) {
    //     dispatch(logout());
    //     localStorage.removeItem("userSession");

    //     console.log(data.message); // Logged out successfully
    //     navigate("/");
    //   } else {
    //     throw new Error(data.message);
    //   }
    // } catch (error) {
    //   console.error("Logout failed:", error);
    // }
    dispatch(logout());
    deleteLocalStorage("userSession");

    console.log("Logged out successfully"); // Logged out successfully
    navigate("/");
  };

  function getMostFrequentlyVisitedStore(orders) {
    let maxCount = 0;
    let mostFrequentStoreTitle = "";

    orders?.forEach((order) => {
      const storeTitle = order.storeTitle;
      const count = orders.filter((o) => o.storeTitle === storeTitle).length;

      if (count > maxCount) {
        maxCount = count;
        mostFrequentStoreTitle = storeTitle;
      }
    });

    return mostFrequentStoreTitle;
  }

  function getRewardDescription(rewardType) {
    switch (rewardType) {
      case "discount":
        return "Discount on next purchase";
      case "freeItem":
        return "Free item on next purchase";
      case "cashback":
        return "Cashback on next purchase";
      case 0:
        return "Free item on next purchase";
      default:
        return "Reward description not available";
    }
  }

  const user = {
    name: latestUserData?.firstName,
    surname: latestUserData?.lastName,
    signUpDate: dayjs(latestUserData?.registrationDate).format("DD-MM-YYYY"),
    lastVisitedStore: latestUserData?.orders?.at(-1)?.storeTitle,
    mostFrequentStore: getMostFrequentlyVisitedStore(latestUserData?.orders),
    loyaltyCards: latestUserData?.loyaltyStamps,
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedLoyaltyCard(null);
  };

  const selectLoyaltyCard = (card) => {
    setSelectedLoyaltyCard(card);
  };

  return (
    <div className="account-page" style={{ padding: "12px" }}>
      {/* <h1>Account Details</h1> */}
      {latestUserData ? (
        <>
          <div
            style={{
              position: "absolute",
              color: "white",
              zIndex: 2,
            }}
          >
            <span
              onClick={() => navigate(-1)}
              style={{ fontSize: "24px", cursor: "pointer" }}
            >
              &larr;
            </span>
          </div>
          <div
            style={{
              position: "relative",
              top: "-12px",
              left: "-12px",
              width: "100vw",
              height: "100px",
              background:
                "linear-gradient(to right, rebeccapurple, hsl(270, 50%, 60%))",
            }}
          >
            <h1
              style={{
                position: "relative",
                fontSize: "24px",
                marginBottom: "24px",
                textAlign: "center",
                top: "35px",
                color: "white",
              }}
            >
              {user.name} {user.surname}
            </h1>
          </div>
          <UserInfoWrapper>
            <p>
              <strong>Name:</strong> {user.name} {user.surname}
            </p>
            <p>
              <strong>Sign Up Date:</strong> {user.signUpDate}
            </p>
            <p>
              <strong>Last Visited Store:</strong> {user.lastVisitedStore}
            </p>
            <p>
              <strong>Most Frequently Visited Store:</strong>{" "}
              {user.mostFrequentStore}
            </p>
          </UserInfoWrapper>
          <Button onClick={showModal}>Show Loyalty Cards</Button>
          <Modal
            title={selectedLoyaltyCard ? null : "Loyalty Cards"}
            isOpen={isModalVisible}
            onClose={closeModal}
          >
            {selectedLoyaltyCard ? (
              <LoyaltyCardWrapper>
                {/* <h3>{selectedLoyaltyCard.company}</h3> */}
                <LoyaltyCard loyaltyCard={selectedLoyaltyCard} />
                <p>
                  Total Stamps collected: {selectedLoyaltyCard?.stampsCollected}
                </p>
                <p>
                  Current Stamp collected:{" "}
                  {selectedLoyaltyCard?.stampsCollected <
                  selectedLoyaltyCard?.stampsRequiredForReward
                    ? selectedLoyaltyCard?.stampsCollected
                    : selectedLoyaltyCard?.stampsCollected %
                      selectedLoyaltyCard?.stampsRequiredForReward}{" "}
                  / {selectedLoyaltyCard?.stampsRequiredForReward}
                </p>
                <p>
                  Total rewards accumulated:{" "}
                  {selectedLoyaltyCard?.rewardsAccumulated}
                </p>
                <p>Total rewards used: {selectedLoyaltyCard?.rewardsUsed}</p>
                <p>
                  Rewards available: {selectedLoyaltyCard?.rewardsAvailable}
                </p>
                <p>
                  Reward Description:{" "}
                  {getRewardDescription(selectedLoyaltyCard?.rewardType)}
                </p>
                <Button onClick={() => setSelectedLoyaltyCard(null)}>
                  Back to list
                </Button>
              </LoyaltyCardWrapper>
            ) : (
              <ul>
                <li style={{ marginBottom: "12px", textAlign: "right" }}>
                  Unique store visits: {user?.loyaltyCards?.length}
                </li>
                {user?.loyaltyCards?.map((card, index) => (
                  <li
                    key={index}
                    style={{ cursor: "pointer", marginBottom: "12px" }}
                    onClick={() => selectLoyaltyCard(card)}
                  >
                    {card?.company}
                  </li>
                ))}
              </ul>
            )}
          </Modal>
          <Button onClick={() => handleLogout()}>Logout</Button>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default UserAccount;

{
  /* <div>
  Rewards - Need to design flow for internal and external FE/BE
</div>
<div>
  Bday coupons/Anniversary coupons/milestone prizes or coupons
</div>
<div>User analytics</div>
<div>Loyalty schemes</div>
<div>Reference link</div>
<div>Share to social media link</div> */
}

const UserInfoWrapper = styled.div`
  p {
    margin-bottom: 6px;
    line-height: 24px;
  }
`;

const LoyaltyCardWrapper = styled.div`
  p {
    margin-bottom: 12px;
  }
`;
