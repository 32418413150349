import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectUser, selectIsAuthenticated } from "state/slices/authSlice";
// Hooks

import Hero from "./home/Hero";
import Explore from "./home/Explore";
import Expertise from "./home/Expertise";
import PricingBlock from "./home/PricingBlock";
import useWidth from "hooks/width.hook";

import orderlyLogo from "../assets/orderly_logo_v0.png";
import qrScanner from "../assets/qr.png";
import { Link } from "react-router-dom";

const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f6f6;
`;

export const HeaderLogo = () => {
  const isUserAuthenticated = useSelector(selectIsAuthenticated);
  const user = useSelector(selectUser) ?? {};

  const { accountType, firstName, storeName } =
    isUserAuthenticated && user.value
      ? user.value
      : isUserAuthenticated && user
      ? user
      : {};

  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 6px",
      }}
    >
      <div
        onClick={async () => {
          try {
            if (!navigator.mediaDevices?.getUserMedia) {
              alert("Sorry, your browser does not support camera access");
              return;
            }

            const stream = await navigator.mediaDevices.getUserMedia({
              video: { facingMode: "environment" },
            });
          } catch (err) {
            console.error("Error accessing camera:", err);
            alert("Could not access camera. Please check permissions.");
          }
        }}
        style={{
          width: "40px",
          height: "70px",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          zIndex: 2,
        }}
      >
        <img
          src={qrScanner}
          alt="qrScanner"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      </div>
      <img
        src={orderlyLogo}
        alt="logo"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
      />
      <SignInButton
        to={{
          pathname:
            accountType === "User"
              ? "/account"
              : accountType === "Store"
              ? "/portal/overview"
              : "/login",
        }}
      >
        {accountType === "User"
          ? firstName
          : accountType === "Store"
          ? storeName
          : "Login"}
      </SignInButton>
    </div>
  );
};

export const Header = () => {
  return (
    <>
      <HeaderContainer>
        <HeaderLogo />
      </HeaderContainer>
    </>
  );
};

const Home = React.memo(() => {
  const width = useWidth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Hero />
      <Explore width={width} />
      <Expertise />
      <PricingBlock />
    </>
  );
});

export default Home;

const SignInButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* position: absolute; */
  /* width: calc(100% - 28px); */
  height: 50px;
  /* bottom: 50px; */
  /* left: 12px; */
  border: 2px solid black;
  border-radius: 3px;
  text-decoration: none;
  color: black;
`;
