import { useEffect, useState } from "react";
import styled from "styled-components";

//flaticon
import preparingOrder from "../../assets/preparing.mp4";
import readyOrder from "../../assets/ready-alarm.mp4";
import readySound from "../../assets/audio/success-1.mp3";

const CollectionNotification = ({ order, orderStatus }) => {
  const [isAcknowledged, setIsAcknowledged] = useState(false);

  useEffect(() => {
    const videoPlayer = document.getElementById("video-player");

    setTimeout(() => {
      videoPlayer.play();
    }, 100);
  }, []);

  useEffect(() => {
    if (orderStatus && !isAcknowledged) {
      navigator.vibrate([
        ...Array.from({ length: 6 }).flatMap(() => [1000, 500]),
      ]);
      new Audio(readySound).play();

      // Set up recurring notifications
      const notificationInterval = setInterval(() => {
        navigator.vibrate([
          ...Array.from({ length: 6 }).flatMap(() => [1000, 500]),
        ]);
        new Audio(readySound).play();
      }, 5000);

      return () => clearInterval(notificationInterval);
    }
  }, [orderStatus, isAcknowledged]);

  return (
    <CollectionNotificationWrapper className="collection-notification">
      <div id="video-container">
        <video
          id="video-player"
          autoplay
          loop
          muted
          playsInline
          key={orderStatus}
          src={orderStatus ? readyOrder : preparingOrder}
          type="video/mp4"
        >
          Your browser does not support the video tag.
        </video>
      </div>
      {orderStatus && !isAcknowledged ? (
        <>
          <h3>{`${
            order?.buyersName ? order?.buyersName + ", your" : "Your"
          } order is ready for collection`}</h3>
          <AcknowledgeButton onClick={() => setIsAcknowledged(true)}>
            I'm on my way
          </AcknowledgeButton>
        </>
      ) : orderStatus && isAcknowledged ? (
        <h3>Thank you, please collect your order</h3>
      ) : (
        <></>
      )}
    </CollectionNotificationWrapper>
  );
};

export default CollectionNotification;

const CollectionNotificationWrapper = styled.div`
  & #video-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & video {
    width: 50%;
  }

  & h3 {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
`;

const AcknowledgeButton = styled.button`
  display: block;
  margin: 20px auto;
  padding: 12px 24px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;
