import React, { useState } from "react";
import styled from "styled-components";

const CashPayment = ({ orderAmount, onSubmit }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit("Cash Payment");
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentAmount>€{orderAmount}</PaymentAmount>
      <PaymentButton type="submit">Submit</PaymentButton>
    </form>
  );
};

export default CashPayment;

const PaymentAmount = styled.div`
  font-weight: bold;
  margin-bottom: 12px;
`;

const PaymentButton = styled.button`
  background: lightgreen;
  border-radius: 3px;
  border: none;
  padding: 12px;
  text-align: center;
  width: 100%;
  font-size: 18px;
  /* font-weight: bold; */
  cursor: pointer;
`;
