import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";

import Header from "../store/Header";
import CollectionNotification from "./CollectionNotification";
import { Item } from "components/checkout/Checkout";
import { Modal } from "components/account/UserAccount";

import { clearCart } from "../../state/slices/cartSlice";
import { selectUser } from "state/slices/authSlice";

const Button = ({ onClick, children }) => {
  return (
    <button
      onClick={onClick}
      style={{ padding: "10px 20px", cursor: "pointer", margin: "5px" }}
    >
      {children}
    </button>
  );
};

const OrderConfirmation = () => {
  const [order, setOrder] = useState(null);
  const [orderStatus, setOrderStatus] = useState(false);
  const [orderCancelled, setOrderCancelled] = useState(false);
  const [cartItems, setCartItems] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { state } = useLocation();

  console.log("ANDY location", state);
  console.log("ANDY order", order);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  console.log("ANDY url data", { urlParams, queryString });

  const orderId = urlParams.get("orderId");
  const deliveryMethod = urlParams.get("deliveryMethod");
  console.log("ANDY url params", { orderId, deliveryMethod });

  const user = useSelector(selectUser) ?? null;
  const { store } = useSelector((state) => state.store);
  const { items: cart } = useSelector((state) => state.cart);
  console.log("ANDY qweqweqweqwe cart", cart);
  // If cart is false, useEffect => /api/orders/getOrderById/${orderId}, and setCartItems(result)

  const earnedLoyaltyPoints =
    state?.loyaltyStampsAmount - state?.useXAmountOfRewards;

  const getOrderStatus = async (orderId) => {
    try {
      const response = await fetch(`/api/orders/getOrderById/${orderId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const order = await response.json();
      setOrder(order);
      setCartItems(order?.order);
      console.log("ANDY fetch order", order);
      if (order.orderStatus === 1) {
        console.log("Order is ready for collection");
        setOrderStatus(true);
      } else if (order.orderStatus === 2) {
        setOrderCancelled(true);
      } else {
        console.log("Order is NOT ready for collection");
      }
      return order;
    } catch (err) {
      console.log("ANDY order fetch failed", err);
      return null;
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!orderStatus) getOrderStatus(orderId);
    }, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [orderId, orderStatus]);

  useEffect(() => {
    if (cart.length === 0) {
      getOrderStatus(orderId);
    } else {
      setCartItems(cart);
    }

    setTimeout(() => {
      dispatch(clearCart());
    }, 3000);
  }, []);

  const totalItems = cartItems?.reduce((total, item) => {
    return total + item.quantity;
  }, 0);

  const navigateToRegistration = async () => {
    let currentOrderData = await getOrderStatus(orderId)
      .then((orderData) => {
        if (orderData) {
          navigate("/login", { state: { orderData } });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleDownloadReceipt = () => {
    window.print();
  };

  return (
    <OrderConfirmationWrapper className="order-confirmation-wrapper">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
          padding: "12px 0",
        }}
      >
        <span
          onClick={() => navigate(-2)}
          style={{ fontSize: "24px", cursor: "pointer" }}
        >
          &larr;
        </span>
        <h3 style={{ fontSize: "18px" }}>Order Confirmation</h3>
      </div>
      <div
        style={{
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 1px 8px",
          padding: "12px",
          marginBottom: "12px",
          borderRadius: "4px",
          fontWeight: 500,
          textAlign: "center",
          fontSize: "24px",
        }}
      >
        <div style={{ marginBottom: "6px" }}>Sit back and relax</div>
        {deliveryMethod === "1" ? (
          <div>Your order is on its way!</div>
        ) : (
          <div>We are preparing your order!</div>
        )}
      </div>
      <div style={{ marginBottom: "12px" }}>
        {(deliveryMethod === "1" || order?.deliveryMethod === 1) && (
          <div>Table Service</div>
        )}
        {(deliveryMethod === "2" || order?.deliveryMethod === 2) && (
          <CollectionNotification order={order} orderStatus={orderStatus} />
        )}
      </div>
      <div style={{ marginBottom: "12px" }}>
        Order Number: <span style={{ fontWeight: "bold" }}>{orderId}</span>
      </div>
      <div
        className="orderConfirmation-cart-items"
        style={{
          margin: "6px 0",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 1px 8px",
          borderRadius: "4px",
        }}
      >
        {/* Order: */}
        {cartItems?.map((item, i) => (
          <Item key={i} item={item} i={i} />
        ))}
      </div>
      <OrderAmountContainer>
        <div>
          Total Items: <span>{totalItems}</span>
        </div>
        {(order?.discountAmount !== "0.00" ||
          state?.discountAmount !== "0.00") && (
          <div>
            Discount:
            <span>€{order?.discountAmount || state?.discountAmount}</span>
          </div>
        )}
        {(order?.rewardSavings !== "0.00" ||
          state?.rewardSavings !== "0.00") && (
          <div>
            Reward Savings:{" "}
            <span>€{order?.rewardSavings || state?.rewardSavings}</span>
          </div>
        )}
        <div>
          Price: <span>€{order?.orderAmount || state?.orderAmount}</span>
        </div>
      </OrderAmountContainer>

      {!user && (
        <div>
          <Button onClick={() => navigateToRegistration()}>
            Create Account
          </Button>
        </div>
      )}

      {earnedLoyaltyPoints > 0 && (
        <div>
          You earned {earnedLoyaltyPoints}{" "}
          {earnedLoyaltyPoints === 1 ? "loyalty point!" : "loyalty points!"}
        </div>
      )}

      <div style={{ textAlign: "center" }}>
        <Button onClick={handleDownloadReceipt}>Download Receipt</Button>
      </div>

      {orderCancelled && (
        <Modal
          title="Order Cancelled"
          isOpen={orderCancelled}
          onClose={() => setOrderCancelled(false)}
        >
          <h3 style={{ textAlign: "center", marginBottom: "12px" }}>
            Your order has been cancelled.
          </h3>
          <p style={{ textAlign: "center", marginBottom: "48px" }}>
            If you did not cancel this order, please contact the store.
          </p>
        </Modal>
      )}

      {/* <div>
        Should collect as much data about order, user and user journey to be
        able to supply partners with analytics data
      </div> */}
    </OrderConfirmationWrapper>
  );
};

export default OrderConfirmation;

const OrderConfirmationWrapper = styled.div`
  padding: 0 12px;
`;

const OrderAmountContainer = styled.div`
  display: grid;
  grid-gap: 12px;
  margin: 6px 0px;
  padding: 12px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 8px;

  & > div {
    // display: grid;
    // grid-template-columns: 92px auto;

    display: flex;
    justify-content: space-between;

    & span {
      font-weight: bold;
    }
  }
`;
