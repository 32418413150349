import React, { useState } from "react";
import styled from "styled-components";
import CallServiceImage from "../../assets/service.png";

const ServiceImg = styled.img`
  position: fixed;
  right: ${({ showAssistance }) => (showAssistance ? "15px" : "15px")};
  bottom: ${({ showAssistance }) => (showAssistance ? "75px" : "15px")};
  width: 20px;
  height: 20px;
  background: lightgreen;
  opacity: ${({ showAssistance }) => (showAssistance ? 1 : 0)};
  border-radius: 100px;
  padding: 10px;
  cursor: pointer;
  z-index: 100;

  &:hover {
    opacity: 1;
  }

  transition: all 0.2s ease-in-out;
`;

const ServiceOptionsContainer = styled.div`
  position: fixed;
  bottom: 75px;
  right: 60px;
  width: calc(100% - 80px);
  height: fit-content;
  background: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  z-index: 99;
`;

const Option = ({ option }) => {
  console.log("ANDY option: ", option);
  const [expandSubOptions, setExpandSubOptions] = useState(false);
  const handleServiceCall = async (service, subOption) => {
    console.log(
      `ANDY Make service call: ${service} -> with subOption: ${subOption}`
    );

    try {
      const response = await fetch(`/api/stores/assistance`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ service, subOption }),
      });

      if (!response.ok) {
        throw new Error("stores/assistance Network response failed.");
      }

      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error("Failed to request assistance:", error);
      throw error;
    }
  };
  return (
    <>
      <div
        onClick={() =>
          option?.subOptions?.length > 0
            ? setExpandSubOptions(!expandSubOptions)
            : handleServiceCall(option?.service, null)
        }
        style={{ fontWeight: "bold", padding: "10px" }}
      >
        {option?.label}
      </div>
      {option?.subOptions?.length > 0 &&
        expandSubOptions &&
        option?.subOptions.map((subOption, i) => {
          return (
            <div
              onClick={() => handleServiceCall(option?.service, subOption)}
              style={{ marginLeft: "12px", padding: "10px" }}
            >
              {subOption}
            </div>
          );
        })}
    </>
  );
};

const ServiceOptions = ({ setServiceActive, options }) => {
  return (
    <>
      {options.map((option, i) => {
        return <Option option={option} />;
      })}
    </>
  );
};

const CallAssistance = ({ data, layout, showAssistance }) => {
  const [serviceActive, setServiceActive] = useState(false);
  console.log("ANDY service State", { serviceActive });
  console.log("ANDY CallAssistance", { data, layout });
  return (
    <>
      <ServiceImg
        src={CallServiceImage}
        alt="Call Service button"
        onClick={() => setServiceActive(!serviceActive)}
        // serviceActive={serviceActive}
        showAssistance={showAssistance}
        className={"callService-img " + layout?.callService?.img}
      />
      {showAssistance && serviceActive && (
        <ServiceOptionsContainer layout={layout}>
          <ServiceOptions
            setServiceActive={(e) => setServiceActive(e)}
            options={[
              {
                label: "Call waiter",
                service: "/call-waiter",
                subOptions: [
                  "Ask for bill",
                  "Ask for utinsils",
                  "Ask to clean area",
                  "Need help with something else",
                ],
              },
              {
                label: "Report an issue",
                service: "/report-issue",
                subOptions: ["Report issue", "Report something else"],
              },
            ]}
          />
        </ServiceOptionsContainer>
      )}
    </>
  );
};

export default CallAssistance;
