// import { useState, useEffect, useRef } from "react";
// import ProgressBar from "components/general/ProgressBar";
// import "../../styles.css";
// import styled from "styled-components";

// const Promotions = ({ promotions, layout }) => {
//   const [activePromo, setActivePromo] = useState(1);
//   const [promoAmount, setPromoAmount] = useState(0);
//   const autoScrollContainerRef = useRef(null);
//   const scrollContainer = autoScrollContainerRef.current;
//   const currentDate = new Date().toISOString();
//   const livePromotions = promotions?.filter(
//     (promo) => promo?.endDate > currentDate
//   );

//   function handleShowPrevPromo() {
//     if (scrollContainer) {
//       if (activePromo !== 1) {
//         scrollContainer.scrollTo({
//           left: scrollContainer.scrollLeft - scrollContainer.clientWidth,
//           behavior: "smooth",
//         });
//         setActivePromo(activePromo - 1);
//       } else {
//         scrollContainer.scrollTo({
//           left:
//             scrollContainer.scrollLeft +
//             scrollContainer.clientWidth * livePromotions?.length,
//           behavior: "smooth",
//         });
//         setActivePromo(livePromotions?.length);
//       }
//     }
//   }

//   function handleShowNextPromo() {
//     if (scrollContainer) {
//       if (activePromo < livePromotions?.length) {
//         scrollContainer.scrollTo({
//           left: scrollContainer.scrollLeft + scrollContainer.clientWidth,
//           behavior: "smooth",
//         });
//         setActivePromo(activePromo + 1);
//       } else {
//         scrollContainer.scrollTo({
//           left:
//             scrollContainer.scrollLeft -
//             scrollContainer.clientWidth * livePromotions?.length,
//           behavior: "smooth",
//         });
//         setActivePromo(1);
//       }
//     }
//   }

//   useEffect(() => {
//     let initialPromo = 1;
//     let scrollContainer = autoScrollContainerRef.current;

//     const intervalId = setInterval(() => {
//       if (initialPromo < livePromotions?.length) {
//         handleShowNextPromo();
//         initialPromo++;
//       } else {
//         if (scrollContainer) {
//           scrollContainer.scrollTo({
//             left:
//               scrollContainer.scrollLeft -
//               scrollContainer.clientWidth * livePromotions?.length,
//             behavior: "smooth",
//           });
//           initialPromo = 1;
//         }
//       }

//       return () => {
//         scrollContainer = null;
//       };
//     }, 10000);

//     return () => {
//       clearInterval(intervalId);
//     };
//   }, [livePromotions, handleShowNextPromo]);

//   console.log("ANDY asdasd", { promotions, livePromotions });
//   return (
//     <>
//       <PromotionsContainer
//         className={"promotions-container " + layout?.promotions?.container}
//         promoamount={livePromotions?.length}
//         ref={autoScrollContainerRef}
//       >
//         {livePromotions.map((promotion, i) => {
//           // endDate
//           // promoCode
//           // startDate

//           return (
//             <Promotion
//               key={
//                 i + promotion?._id + promotion?.name + promotion?.description
//               }
//               className={
//                 "promotions-promotion " + layout?.promotions?.promotion
//               }
//               backgroundcolour={promotion?.backgroundColor}
//               backgroundimage={promotion?.backgroundImage}
//             >
//               <PromotionTitle
//                 className={"promotions-title " + layout?.promotions?.title}
//               >
//                 {promotion?.name}
//               </PromotionTitle>
//               <PromotionInfo
//                 className={"promotion-info " + layout?.promotions?.info}
//               >
//                 {promotion?.description}
//               </PromotionInfo>
//               <ProgressBar />
//             </Promotion>
//           );
//         })}
//       </PromotionsContainer>
//     </>
//   );
// };

// export default Promotions;

// const PromotionsContainer = styled.div`
//   display: grid;
//   grid-template-columns: repeat(
//     ${({ promoamount }) => promoamount && promoamount},
//     100%
//   );
//   width: 100%;
//   height: 150px;
//   scroll-snap-type: x mandatory;
//   overflow-x: scroll;
//   /* width */
//   &::-webkit-scrollbar {
//     width: 0px;
//     height: 0px;
//   }
//   /* Track */
//   &::-webkit-scrollbar-track {
//     background: #f1f1f1;
//   }
//   /* Handle */
//   &::-webkit-scrollbar-thumb {
//     background: #888;
//   }
//   /* Handle on hover */
//   &::-webkit-scrollbar-thumb:hover {
//     background: #555;
//   }
// `;
// const Promotion = styled.div`
//   position: relative;
//   background-image: url(${({ backgroundimage }) =>
//     backgroundimage && backgroundimage});
//   background: ${({ backgroundcolour, backgroundimage }) =>
//     backgroundcolour && !backgroundimage && backgroundcolour};
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;

//   width: 100%;
//   height: 100%;

//   display: grid;
//   //   align-items: center;
//   justify-content: center;
//   text-align: center;
//   scroll-snap-align: center;
// `;
// const PromotionTitle = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: flex-end;
//   margin-bottom: 12px;

//   font-size: 24px;
// `;
// const PromotionInfo = styled.div`
//   font-size: 12px;
// `;

import { useState, useEffect, useRef, useCallback } from "react";
// import ProgressBar from "components/general/ProgressBar";
import "../../styles.css";
import styled from "styled-components";

const Promotions = ({ promotions, layout }) => {
  const [activePromo, setActivePromo] = useState(0);
  const autoScrollContainerRef = useRef(null);
  const timerRef = useRef(null);
  const currentDate = new Date().toISOString();

  const livePromotions = promotions?.filter(
    // this should be handled in the backend
    (promo) => currentDate >= promo?.startDate && currentDate <= promo?.endDate
  );

  const handleShowNextPromo = useCallback(() => {
    setActivePromo((prev) => (prev + 1 < livePromotions.length ? prev + 1 : 0));
  }, [livePromotions.length]);

  const handleShowPrevPromo = () => {
    setActivePromo((prev) =>
      prev - 1 >= 0 ? prev - 1 : livePromotions.length - 1
    );
  };

  const resetAutoScrollTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    timerRef.current = setInterval(() => {
      handleShowNextPromo();
    }, 10000);
  }, [handleShowNextPromo]);

  useEffect(() => {
    resetAutoScrollTimer();
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [resetAutoScrollTimer]);

  useEffect(() => {
    const scrollContainer = autoScrollContainerRef.current;
    if (scrollContainer && livePromotions.length > 0) {
      scrollContainer.scrollTo({
        left: activePromo * scrollContainer.clientWidth,
        behavior: "smooth",
      });
    }
  }, [activePromo, livePromotions.length]);

  return (
    <PromotionsWrapper>
      <Arrow
        direction="left"
        onClick={() => {
          handleShowPrevPromo();
          resetAutoScrollTimer();
        }}
      >
        &lt;
      </Arrow>
      <PromotionsContainer
        className={`promotions-container ${layout?.promotions?.container}`}
        promoamount={livePromotions?.length}
        ref={autoScrollContainerRef}
      >
        {livePromotions.map((promotion, index) => (
          <Promotion
            key={`${promotion?._id}-${index}`}
            className={`promotions-promotion ${layout?.promotions?.promotion}`}
            backgroundcolour={promotion?.backgroundColor}
            backgroundimage={promotion?.backgroundImage}
          >
            <PromotionTitle
              className={`promotions-title ${layout?.promotions?.title}`}
              textColor={promotion?.textColor}
            >
              {promotion?.name}
            </PromotionTitle>
            <PromotionInfo
              className={`promotion-info ${layout?.promotions?.info}`}
              textColor={promotion?.textColor}
            >
              {promotion?.description}
            </PromotionInfo>
            {/* <ProgressBar /> */}
          </Promotion>
        ))}
      </PromotionsContainer>
      <Arrow
        direction="right"
        onClick={() => {
          handleShowNextPromo();
          resetAutoScrollTimer();
        }}
      >
        &gt;
      </Arrow>
      <DotsContainer>
        {livePromotions.map((_, index) => (
          <Dot
            key={index}
            active={index === activePromo}
            onClick={() => setActivePromo(index)}
          />
        ))}
      </DotsContainer>
    </PromotionsWrapper>
  );
};

export default Promotions;

const PromotionsWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Arrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props) => (props.direction === "left" ? "left: 10px;" : "right: 10px;")}
  background: rgba(224, 224, 224, 0.7);
  border: 1px solid #2d2c2c4a;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s ease;

  font-size: 16px;
  font-family: "Courier New", Courier, math, auto;
  font-weight: bold;
  color: #2d2c2c;

  &:hover {
    background: rgba(224, 224, 224, 0.9);
  }
`;

const PromotionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${({ promoamount }) => promoamount && promoamount},
    100%
  );
  width: 100%;
  height: 150px;
  scroll-snap-type: x mandatory;
  /* overflow-x: scroll; // Add these 2 line to enable horizontal scrolling
  scroll-behavior: smooth; */

  overflow-x: hidden; // Comment these 3 lines to enable horizontal scrolling
  user-select: none;
  pointer-events: none;

  /* Hide scrollbar */
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;

const Promotion = styled.div`
  position: relative;
  background-image: ${({ backgroundimage }) =>
    backgroundimage ? `url(${backgroundimage})` : "none"};
  background-color: ${({ backgroundcolour, backgroundimage }) =>
    backgroundcolour && !backgroundimage ? backgroundcolour : "transparent"};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  width: 100%;
  height: 100%;

  display: grid;
  justify-content: center;
  text-align: center;
  scroll-snap-align: center;
`;

const PromotionTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 12px;
  color: ${({ textColor }) => textColor || "black"};

  font-size: 24px;
`;

const PromotionInfo = styled.div`
  color: ${({ textColor }) => textColor || "black"};
  font-size: 12px;
`;

const DotsContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1;
`;

const Dot = styled.div`
  width: ${(props) => (props.active ? "12px" : "8px")};
  height: ${(props) => (props.active ? "12px" : "8px")};
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? "rgba(255,255,255,0.9)" : "rgba(255,255,255,0.4)"};
  cursor: pointer;
  transition: all 0.3s ease;
  outline: 1px solid lightgrey;

  &:hover {
    transform: scale(1.1);
  }
`;
