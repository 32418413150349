import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../styles.css";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { updateItemQuantity, removeItem } from "../../state/slices/cartSlice";
import { setTable } from "../../state/slices/storeSlice";
import Close from "components/general/Close";

const Item = ({ item, i, handleQuantityChange, handleRemoveItem, layout }) => {
  const id = item?.id;
  const quantity = item?.quantity;
  const itemDetails = item?.payload?.item;
  // const itemExtras = item?.payload?.itemExtras;
  const itemExtras = item?.payload?.extras;

  let itemExtrasTotal = 0;

  // if (itemExtras) {
  //   Object.values(itemExtras)?.forEach((value) => {
  //     const [_type, cost] = value?.split(",");
  //     itemExtrasTotal += Number(cost);
  //   });
  // }
  if (itemExtras) {
    Object.entries(itemExtras).forEach(([groupName, selectedValues]) => {
      const extraGroup = itemDetails.extras?.find(
        (e) => e.option === groupName
      );
      if (extraGroup) {
        const values = Array.isArray(selectedValues)
          ? selectedValues
          : [selectedValues];
        values.forEach((value) => {
          const option = extraGroup.options.find((opt) => opt.value === value);
          if (option) {
            itemExtrasTotal += Number(option.cost);
          }
        });
      }
    });
  }

  return (
    <ItemContainer
      key={i + id + itemDetails?.name}
      className={"cart-item " + layout?.cart?.item}
    >
      {/* <select
        className={
          "cart-itemQuantitySelect " + layout?.cart?.itemQuantitySelect
        }
        value={item.quantity}
        onChange={(e) =>
          handleQuantityChange(item.id, itemExtras, parseInt(e.target.value))
        }
      >
        {[...Array(10).keys()].map((number) => (
          <option
            className={
              "cart-itemQuantityOption " + layout?.cart?.itemQuantityOption
            }
            key={number}
            value={number}
          >
            {number}
          </option>
        ))}
      </select>
      <button
        className={"cart-deleteItem " + layout?.cart?.deleteItem}
        onClick={() => handleRemoveItem(item.id, itemExtras)}
      >
        Delete
      </button> */}
      <div
        style={{
          "margin-bottom": "12px",
          "font-weight": "bold",
        }}
        className={"cart-itemName " + layout?.cart?.itemName}
      >
        {itemDetails?.name}
      </div>
      {/* <div
        style={{ display: "flex", justifyContent: "flex-end" }}
        className={"cart-itemQuantity " + layout?.cart?.itemQuantity}
      >
        x{quantity}
      </div> */}
      <div style={{ display: "flex", gap: "3px" }}>
        <select
          className={
            "cart-itemQuantitySelect " + layout?.cart?.itemQuantitySelect
          }
          value={item.quantity}
          onChange={(e) =>
            handleQuantityChange(item.id, itemExtras, parseInt(e.target.value))
          }
          style={{ height: "20px" }}
        >
          {[...Array(21).keys()].map(
            (number) =>
              number !== 0 && (
                <option
                  className={
                    "cart-itemQuantityOption " +
                    layout?.cart?.itemQuantityOption
                  }
                  key={number}
                  value={number}
                >
                  {number}
                </option>
              )
          )}
        </select>
        <button
          className={"cart-deleteItem " + layout?.cart?.deleteItem}
          onClick={() => handleRemoveItem(item.id, itemExtras)}
          style={{
            background: "red",
            border: "none",
            color: "white",
            width: "20px",
            height: "20px",
            "justify-self": "end",
          }}
        >
          ×
        </button>
      </div>
      {itemExtras && (
        <div style={{ fontSize: "14px", display: "grid", gap: "6px" }}>
          {Object.entries(itemExtras).map(([groupName, selectedValues], i) => {
            const extraGroup = itemDetails.extras?.find(
              (e) => e.option === groupName
            );
            if (!extraGroup) return null;

            return (
              <div key={i}>
                <div>
                  <strong>{groupName}:</strong>
                </div>
                {Array.isArray(selectedValues) ? (
                  selectedValues.map((value, idx) => {
                    const option = extraGroup.options.find(
                      (opt) => opt.value === value
                    );
                    return (
                      <div key={idx}>
                        - {option?.text}
                        {option?.cost > 0 && ` (+€${option.cost.toFixed(2)})`}
                      </div>
                    );
                  })
                ) : (
                  <div>
                    -{" "}
                    {
                      extraGroup.options.find(
                        (opt) => opt.value === selectedValues
                      )?.text
                    }
                    {extraGroup.options.find(
                      (opt) => opt.value === selectedValues
                    )?.cost > 0 &&
                      ` (+€${extraGroup.options
                        .find((opt) => opt.value === selectedValues)
                        ?.cost.toFixed(2)})`}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      <div
        style={{
          gridColumn: "2 / 3",
          display: "grid",
          justifyContent: "flex-end",
          alignItems: "end",
          fontWeight: "bold",
        }}
        className={"cart-itemCost " + layout?.cart?.itemCost}
      >
        €
        {((Number(itemDetails?.price) + itemExtrasTotal) * quantity).toFixed(2)}
      </div>
    </ItemContainer>
  );
};

const Cart = ({ items, layout }) => {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [cartTotal, setCartTotal] = useState(0);
  const [quantity, setQuantity] = useState(0);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const tableQueryParam = urlParams.get("table");

  const { table } = useSelector((state) => state.store);

  useEffect(() => {
    if (!table) {
      dispatch(setTable(tableQueryParam));
    }
  }, []);

  useEffect(() => {
    let totalAmount = 0;
    let totalQuantity = 0;

    items?.forEach((item) => {
      const itemDetails = item?.payload?.item;
      const itemExtras = item?.payload?.extras;
      let itemExtrasTotal = 0;

      // Calculate extras total
      if (itemExtras) {
        Object.entries(itemExtras).forEach(([groupName, selectedValues]) => {
          const extraGroup = itemDetails.extras?.find(
            (e) => e.option === groupName
          );
          if (extraGroup) {
            const values = Array.isArray(selectedValues)
              ? selectedValues
              : [selectedValues];
            values.forEach((value) => {
              const option = extraGroup.options.find(
                (opt) => opt.value === value
              );
              if (option) {
                itemExtrasTotal += Number(option.cost || 0);
              }
            });
          }
        });
      }

      // Calculate item total including extras and quantity
      const itemTotal =
        (Number(itemDetails?.price || 0) + itemExtrasTotal) * item.quantity;
      totalAmount += itemTotal;
      totalQuantity += item.quantity;
    });

    setCartTotal(totalAmount.toFixed(2));
    setQuantity(totalQuantity);
  }, [items]);

  const dispatch = useDispatch();
  // dispatch(addItem({ id: item?.id, payload: item, quantity: itemQty }));

  const handleQuantityChange = (itemId, itemExtras, newQuantity) => {
    // itemId is not unique enough, this will always update the first item added with this itemId regardless of extras.
    // Need to
    dispatch(
      updateItemQuantity({
        id: itemId,
        extras: itemExtras,
        quantity: newQuantity,
      })
    );
  };

  const handleRemoveItem = (itemId, itemExtras) => {
    dispatch(removeItem({ id: itemId, extras: itemExtras }));
  };

  return (
    <>
      <CartContainer
        className={"cart-container " + layout?.cart?.container}
        isCartOpen={isCartOpen}
      >
        {isCartOpen ? (
          <>
            <Close
              onClick={() => setIsCartOpen(false)}
              position="relative"
              styles={{ top: "-17px" }}
            />
            <div
              style={{
                "overflow-y": "scroll",
                padding: "5px",
                "border-top": "1px solid lightgrey",
                "border-bottom": "1px solid lightgrey",
                "box-shadow": "0px 0px 15px rgba(0, 0, 0, 0.1)",
              }}
              className={"cart-itemscontainer " + layout?.cart?.itemscontainer}
            >
              {items.map((item, i) => (
                <Item
                  item={item}
                  i={i}
                  handleQuantityChange={handleQuantityChange}
                  handleRemoveItem={handleRemoveItem}
                  layout={layout}
                />
              ))}
            </div>
            <div
              className={"cart-cartTableTotal " + layout?.cart?.cartTableTotal}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                className={"cart-cartTable " + layout?.cart?.cartTable}
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  gap: "5px",
                  height: "30px",
                }}
              >
                Table:{" "}
                <input
                  type="number"
                  placeholder={table || tableQueryParam || "0"}
                  style={{ width: "23px" }}
                  onChange={(e) => dispatch(setTable(e.target.value))}
                />
              </div>
              <div
                style={{
                  "font-weight": "bold",
                }}
                className={"cart-cartTotal " + layout?.cart?.cartTotal}
              >
                Total: €{cartTotal}
              </div>
            </div>
            <Link
              to={{
                pathname: "/store/checkout",
              }}
              state={{ items }}
            >
              <button
                className={
                  "cart-continueButton " + layout?.cart?.continueButton
                }
                style={{
                  width: "100%",
                  height: "35px",
                  background: "lightgreen",
                  border: "none",
                  "border-radius": "3px",
                  "font-size": "16px",
                  "font-weight": 600,
                }}
              >
                Checkout
              </button>
            </Link>
          </>
        ) : (
          <div
            onClick={() => setIsCartOpen(true)}
            style={{
              display: "flex",
              "justify-content": "space-evenly",
              "align-items": "center",
              "font-size": "18px",
              "font-weight": "bold",
            }}
          >
            <div>Total Cart items</div>
            <div>{quantity}</div>
          </div>
        )}
      </CartContainer>
    </>
  );
};

export default Cart;

const CartContainer = styled.div`
  // position: fixed;
  // bottom: 75px;
  // right: 5px;
  // width: 40px;
  // height: 40px;
  // background: rgba(255, 255, 255, 1);
  // opacity: 0.9;
  // border-radius: 100px;
  // padding: 10px;
  // cursor: pointer;
  // z-index: 100;

  display: grid;
  grid-template-rows: ${({ isCartOpen }) =>
    isCartOpen ? "6% 75% auto auto" : "auto"};

  position: fixed;
  bottom: 5px;
  width: ${({ isCartOpen }) =>
    isCartOpen ? `calc(100% - 36px)` : `calc(100% - 100px)`};
  height: 40px;
  background: rgba(255, 255, 255);
  opacity: 0.8;
  border-radius: 3px;
  padding: 10px;
  margin: 0 8px;
  cursor: pointer;
  z-index: ${({ isCartOpen }) => (isCartOpen ? 100 : 98)};
  box-shadow: rgba(0, 0, 0, 1) 0px 3px 8px;

  &:hover {
    opacity: 1;
  }

  ${({ isCartOpen }) =>
    isCartOpen &&
    `
    border-radius: 0px;
    // width: 300px;
    // height: 350px;
    height: 50vh;
    opacity: 1;
    `}

  transition: all 0.2s ease-in-out;
`;
const ItemContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  margin-bottom: 6px;
  padding: 6px;
  border-bottom: 1px solid grey;
`;
